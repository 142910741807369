const coins = [
  {
    "name": "bitcoin",
    "title": "Bitcoin",
    "ticker": "BTC",
  },
  {
    "name": "ethereum",
    "title": "Ethereum",
    "ticker": "ETH",
  },
  {
    "name": "tether",
    "title": "Tether Coin",
    "ticker": "USDT",
  },
  {
    "name": "usd-coin",
    "title": "USD Coin",
    "ticker": "USDC",
  },
  {
    "name": "ripple",
    "title": "Ripple",
    "ticker": "XRP",
  },
  {
    "name": "cardano",
    "title": "Cardano",
    "ticker": "ADA",
  },
  {
    "title": "Solana",
    "name": "solana",
    "ticker": "SOL",
  },
  {
    "title": "Dogecoin",
    "name": "dogecoin",
    "ticker": "DOGE",
  },
  {
    "title": "Dai",
    "name": "dai",
    "ticker": "DAI",
  },
  {
    "title": "Polkadot",
    "name": "polkadot",
    "ticker": "DOT",
  },
  {
    "title": "TRON",
    "name": "tron",
    "ticker": "TRX",
  },
  {
    "title": "Shiba Inu",
    "name": "shiba-inu",
    "ticker": "SHIB",
  },
  {
    "title": "Avalanche",
    "name": "avalanche-2",
    "ticker": "AVAX",
  },
  {
    "title": "Wrapped Bitcoin",
    "name": "wrapped-bitcoin",
    "ticker": "WBTC",
  },
  {
    "title": "Polygon",
    "name": "matic-network",
    "ticker": "MATIC",
  },
  {
    "title": "Uniswap",
    "name": "uniswap",
    "ticker": "UNI",
  },
  {
    "title": "Litecoin",
    "name": "litecoin",
    "ticker": "LTC",
  },
  {
    "title": "Chainlink",
    "name": "chainlink",
    "ticker": "LINK",
  },
  {
    "title": "Lumen",
    "name": "stellar",
    "ticker": "XLM",
  },
  {
    "title": "Cosmos",
    "name": "cosmos",
    "ticker": "ATOM",
  },
  {
    "title": "NEAR Protocol",
    "name": "near",
    "ticker": "NEAR",
  },
  {
    "title": "Monero",
    "name": "monero",
    "ticker": "XMR",
  },
  {
    "title": "Algorand",
    "name": "algorand",
    "ticker": "ALGO",
  },
  {
    "title": "Ethereum Classic",
    "name": "ethereum-classic",
    "ticker": "ETC",
  },
  {
    "title": "Bitcoin Cash",
    "name": "bitcoin-cash",
    "ticker": "BCH",
  }
]

export default coins