import React from 'react';
import { CoinTable } from './components';

const App = () => {
  return (
    <>
      <CoinTable />
    </>
  );
}

export default App;
