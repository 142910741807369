import React from 'react';

import { CoinType, InitialCoinType } from './types';
import coinsList from './coins'
import CoinPrice from '../CoinPrice';



const formatCoinData = (coin: CoinType): CoinType => {
  let marketCapFormatter = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1, style: 'currency', currency: 'EUR' });

  return {
    ...coin,
    marketCap: marketCapFormatter.format(+coin.marketCap),
    price: coin.price,
    change: {
      ...coin.change,
      precentage: `${(+coin.change.precentage).toFixed(2)}%`
    }
  }

}

const CoinTable = ({ defaultLimit = 8, maxLimit = 25}: { defaultLimit?: number, maxLimit?: number }) => {
  const [rows, setRows] = React.useState<CoinType[]>([]);
  const [limit, setLimit] = React.useState(defaultLimit);

  const getRows = React.useCallback(async (coins: InitialCoinType[]) => {
    const coinImages = await Promise.all(coins.map(async (coin) => {
      const response = await fetch(`https://api.coingecko.com/api/v3/coins/${coin.name}?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false&vs_currency=eur&days=1`);
      const data = await response.json();

      const marketCap = data.market_data.market_cap.eur;
      
      return formatCoinData({
        ...coin,
        iconUrl: data.image.small,
        marketCap,
        change: {
          value: data.market_data.price_change_24h,
          precentage: data.market_data.price_change_percentage_24h,
          isNegative: data.market_data.price_change_percentage_24h < 0
        },
        price: data.market_data.current_price.eur
      })
    }));
    setRows(coinImages);
  }, []);

  React.useEffect(() => {
    getRows(coinsList.slice(0, limit));

    const interval = setInterval(() => getRows(coinsList.slice(0, limit)), 60000);
    
    return () => clearInterval(interval);
  }, [limit, getRows]);

  const handleClick = () => {
    setLimit(stateLimit => stateLimit + defaultLimit > maxLimit ? maxLimit : stateLimit + defaultLimit);
  }

    
  return (
    <>
    <section className="section prices">
        <div className="grid-cont">
          <h2>Cryptocurrencies prices</h2>
          <table className="prices__table">
            <thead className="p--xs">
              <tr>
                <th colSpan={3}>Cryptocurrency</th>
                <th>Price</th>
                <th>24H % Chg</th>
                <th>Market Cap</th>
              </tr>
            </thead>
            <tbody>
              {rows.map(row => (
                <tr>
                <td><img src={row.iconUrl} alt={row.name} style={{ width: '20px', marginRight: '10px' }} /></td>
                <td>{row.ticker}</td>
                <td>{row.title}</td>
                <td><CoinPrice price={row.price} /></td>
                <td className={row.change.isNegative ? "loss" : "grow"}>{row.change.precentage}</td>
                <td>{row.marketCap}</td>
              </tr>
              ))}
            </tbody>
          </table>
          {(limit !== maxLimit) && <button onClick={handleClick} className="button button--outline">Load more</button>}
        </div>
      </section>
    </>
  );
}


export default CoinTable;
