import React from 'react'
import AnimatedNumbers from "react-animated-numbers";

const CoinPrice = ({ price }: { price: number | string }) => {
    return (
        <div style={{ display: 'flex', marginLeft: '20vw', textAlign: 'end' }}>
            <span style={{marginLeft: 'auto' }}>€</span>
            <AnimatedNumbers
        includeComma
        animateToNumber={+price}
        fontStyle={{ fontSize: 18 }}
        configs={[
          { mass: 1, tension: 500, friction: 100 },
          { mass: 1, tension: 500, friction: 130 },
          { mass: 1, tension: 500, friction: 90 },
          { mass: 1, tension: 500, friction: 135 },
          { mass: 1, tension: 500, friction: 100 },
          { mass: 1, tension: 500, friction: 180 },
        ]}
      />
      </div>    
    )
}

export default CoinPrice